import React, { createContext, useContext, useState } from "react";

const LoaderContext = createContext({ loading: false });

function LoaderProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      <>
        <div style={{ position: "relative" }}>
          {loading && (
            <>
              <div
                style={{
                  position: "absolute",
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  zIndex: 1000,
                  userSelect: "none",
                }}
              >
                <img
                  src="/assets/images/assertion.png"
                  alt=""
                  height={30}
                  width={200}
                />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            filter: loading ? "blur(10px)" : "",
            WebkitFilter: loading ? "blur(10px)" : "",
            height: "100%",
          }}
        >
          {children}
        </div>
      </>
    </LoaderContext.Provider>
  );
}

const useLoader = () => useContext(LoaderContext);

export { useLoader, LoaderProvider };
